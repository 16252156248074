import {React} from 'react';
function Passes () {
 
    return (
        <section id="passes">
        <div className="passes bg-black py-md-5">
          <div className="container-xl py-5">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="wrapper text-center text-md-start mb-5 mb-md-0 pe-md-3">
                  <img src="img/passes.png" alt="Passes" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-7">
                <div className="wrapper text-center text-md-end">
                  <h2 className="fs-72 lh-1 text-white mb-5">Our <span className="text-mastad">GENESIS PASS</span> NFT</h2>
                  <p className="fs-18 lh-sm text-white">Our GENESIS PASS will be minted as an NFT (non-fungible token) on the Ethereum 
                    blockchain. There will be a total of 3,600 passes available during this minting process, 
                    600 of which will be our special VIP pass with additional benefits. These VIP passes will 
                    be randomized within the ticket minting pool and feature their own unique graphic. This means everyone who mints has an equal chance of scoring a VIP pass. </p>
                  <p className="fs-18 lh-sm text-white"> Additionally, you may sell your ticket on the open market via numerous marketplace 
                    platforms such as OpenSea™ or LooksRare™. This means that you could potentially 
                    recover your entire ticket price after the event!</p>
                  <p className="fs-18 lh-sm text-white">Finally, if you cannot make it to Metaverse Miami this year, next year, etc........why 
                    not rent your ticket to someone else so they can attend Metaverse Miami? We will 
                    have a dedicated rental system for those who want us to handle this aspect for them!</p>
                  <p className="fs-18 lh-sm text-white mb-0">You OWN your experience!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
export default Passes;
