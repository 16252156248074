import {React} from 'react';
function Partners () {
 
    return (
        <section id="partners">
        <div className="partners pt-5 pb-4">
          <div className="container-xl">
            <div className="row align-items-center">
              <div className="col-12 order-1">
                <div className="wrapper mb-4">
                  <p className="fs-30 lh-1 text-uppercase text-mastad text-center">IN PARTNERSHIP WITH</p>
                </div>
              </div>
              <div className="col-md-4 order-2">
                <div className="wrapper text-center">
                  <a href="#" target="_blank"><img src="img/rugradio.png" alt="" className="img-fluid" /></a>
                </div>
              </div>
              <div className="col-md-4 order-4 order-md-3">
                <div className="wrapper text-center">
                <a href="#" target="_blank"><img src="img/herald.png" alt="" className="img-fluid" /></a>
                </div>
              </div>
              <div className="col-md-4 order-3">
                <div className="wrapper text-center">
                <a href="#" target="_blank"><img src="img/timepieces.png" alt="" className="img-fluid" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
export default Partners;



