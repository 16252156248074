import {React} from 'react';
function Footer () {
 
    return (
        <footer className="bg-black">
        <div className="container-xl py-4">
          <div className="row">
            <div className="col-12">
              <div className="wrapper text-center">
                <p className="fs-24 fw-normal lh-1 text-capitalize text-white mb-0">Copyright 2022.  Metaverse Miami.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
}
export default Footer;