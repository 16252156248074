import {React} from 'react';
function Marketplace () {
 
    return (
        <section id="marketplace">
        <div className="marketplace bg-black py-md-5">
          <div className="container-xl py-5">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="wrapper text-center text-md-start mb-5 mb-md-0 pe-md-3">
                  <img src="img/edenroc.png" alt="edenroc" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-7">
                <div className="wrapper text-center text-md-end">
                  <p className="fs-30 lh-1 fw-normal text-mastad text-uppercase mb-4">THE OFFICIAL EVENT VENUE</p>
                  <h2 className="fs-72 lh-1 text-white mb-3">EDEN <span className="text-mastad">ROC</span></h2>
                  <p className="fs-30 lh-1 fw-normal text-white mb-5">Miami Beach, FL</p>
                  <p className="fs-18 lh-sm text-white">An icon designed by famed architect Morris Lapidus in 1955, Eden Roc Miami Beach brings 
                    plenty of nostalgic glamour to the modern beachfront resort experience. The elite of 
                    Old Hollywood were charmed by our timeless style and upbeat ambiance, and for today’s 
                    curious, carefree, culturally attuned guest the same still holds true at this legendary resort.</p>
                  <p className="fs-18 lh-sm text-white mb-4">Bright, spacious guest rooms with big balconies put you at the edge of the sea with 
                    direct ocean views. Three sparkling beachfront pools and an exclusive strip of beach invite you 
                    to let loose in a relaxed, fun, tropical setting. With vibrant nightlife and world-famous cultural 
                    attractions just minutes away, there's a little bit of everything for everybody here at Eden Roc.</p>
                  <a href="https://be.synxis.com/?hotel=59764&config=groups&group=2022METAV" className="btn btn-border">MAKE RESERVATION</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
export default Marketplace;
