import {React} from 'react';
function Sponsors () {
 
    return (
        <section id="sponsors">
        <div className="sponsors py-5">
          <div className="container-xl">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="wrapper mb-4">
                  <p className="fs-30 lh-1 text-uppercase text-mastad text-center">SPONSORED BY</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="wrapper text-center">
                <a href="#" target="_blank">
                  <img src="img/rugbottom.png" alt="Rug Radio" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="wrapper text-center">
                <a href="#" target="_blank">
                  <img src="img/kongs.png" alt="Chilled Kongs" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="wrapper text-center">
                <a href="#" target="_blank">
                  <img src="img/tokenbottom.png" alt="Token Proof" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="wrapper text-center">
                <a href="#" target="_blank">
                  <img src="img/cathbottom.png" alt="Catharsis Design" className="img-fluid" />
                </a>
                </div>
              </div>
     <div className="col-md-3">
                <div className="wrapper text-center">
                <a href="#" target="_blank">
                  <img src="img/timebottom.png" alt="Time Pieces" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-md-3">
                <div className="wrapper text-center">
                <a href="#" target="_blank">
                  <img src="img/nf3bottom.png" alt="NF3" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-md-3">
                <div className="wrapper text-center">
                <a href="#" target="_blank">
                  <img src="img/dadsbottom.png" alt="CryptoDads" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-md-3">
                <div className="wrapper text-center">
                <a href="#" target="_blank">
                  <img src="img/mythbottom.png" alt="Myth Division" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-md-3">
                <div className="wrapper text-center">
                <a href="#" target="_blank">
                  <img src="img/atlasbottom.png" alt="Atlas" className="img-fluid" />
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
export default Sponsors;
