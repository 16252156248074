import {React} from 'react';
function Header () {
 
    return (
        <nav className="navbar navbar-custom wrapper">
        <div className="container-xl w-100">
          <div className="row w-100 m-0 align-items-center">
            <div className="col-auto d-md-none ms-auto">
              <ul className="navbar-nav flex-row ms-auto align-items-center">
                <li className="nav-item">
                  <a className="nav-link" href="https://opensea.io/collection/metaverse-miami-genesis" target="_blank">
                    <img alt="opensea" width={30} height={30} src="img/opensea2.png" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://discord.gg/SzTne2vYmT" target="_blank">
                    <img alt="discord" width={30} height={30} src="img/discord2.png" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://twitter.com/metaversemiami" target="_blank">
                    <img alt="twitter" width={30} height={30} src="img/twitter.png" />
                  </a>
                </li>
              </ul>
            </div>
            <input className="form-check-input d-none" type="checkbox" id="flexCheckDefault" />
            <label className="form-check-label d-flex d-md-none" htmlFor="flexCheckDefault">
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </label>
            <div className="col-auto navbar-main d-flex flex-row mx-auto w-100 justify-content-between px-0 px-md-2">
              <ul className="navbar-nav navbar-links flex-column flex-md-row me-auto align-items-center">
                <li className="nav-item">
                  <a className="nav-link" href="#speakers">SPEAKERS</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#sponsors">SPONSORS</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#passes">PASSES</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">MARKETPLACE</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="mailto:support@metaverse.miami">CONTACT</a>
                </li>
              </ul>
              <ul className="navbar-nav flex-column flex-md-row ms-auto align-items-center d-none d-md-flex">
                <li className="nav-item desktop-icons">
                  <a className="nav-link" href="https://opensea.io/collection/metaverse-miami-genesis" target="_blank">
                    <img alt="opensea" width={50} height={50} src="img/opensea2.png" />
                  </a>
                </li>
                <li className="nav-item desktop-icons">
                  <a className="nav-link" href="https://discord.gg/SzTne2vYmT" target="_blank">
                    <img alt="discord" width={50} height={50} src="img/discord2.png" />
                  </a>
                </li>
                <li className="nav-item desktop-icons">
                  <a className="nav-link" href="https://twitter.com/metaversemiami" target="_blank">
                    <img alt="twitter" width={50} height={50} src="img/twitter.png" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  
}
export default Header;
