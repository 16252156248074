import React, {useEffect, useState} from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import swal from 'sweetalert';
import { contract_address, contract_abi } from './config.js';

const Hero = () => {
    useEffect(() => {
        // connect_wallet();
    }, [])

    const [mintNumber, setMintNumber] = useState(1);
    const [price, setPrice] = useState(0);
    const [ethProvider, setProvider] = useState(null);
    const [contract, setContract] = useState(null);
    const [saleStatus, setSaleStatus] = useState(0);
    const [address, setAddress] = useState('');

    const mintButtonClickHandler = () => {
        sale_controller();
    }

    const connect_wallet = async () => {
        if (Web3.givenProvider) {
            const providerOptions = {
                /* See Provider Options Section */
            };

            const web3Modal = new Web3Modal({
                network: "mainnet", // optional
                cacheProvider: true, // optional
                providerOptions // required
            });

            const provider = await web3Modal.connect();
            const web3 = new Web3(provider);
            setProvider(web3);

            const contract = new web3.eth.Contract(contract_abi, contract_address);
            setContract(contract);

            let whiteListBlock = await contract.methods.whitelistMintStartBlock().call();
            let mainBlock = await contract.methods.publicMintStartBlock().call();
            let currentBlock = await web3.eth.getBlockNumber();

            if (currentBlock > whiteListBlock && currentBlock < mainBlock) {


                setSaleStatus(1)
            } else if (currentBlock > mainBlock) {

                setSaleStatus(2);
            } else {

                setSaleStatus(0);
                swal("Web3 Connection Error");
            }

            const addresses = await web3.eth.getAccounts();
            setAddress(addresses[0]);

            let mintPrice = await contract.methods.MINT_PRICE().call();
            setPrice(mintPrice);

        } else {
            swal("Web3 Not Found");
        }
    }

    async function show_error_alert(error) {
        let temp_error = error.message.toString();

        let error_list = [
            "It's not time yet",
            "Sent Amount Wrong",
            "Max Supply Reached",
            "You have already Claimed Free Nft.",
            "Presale have not started yet.",
            "You are not in Presale List",
            "Presale Ended.",
            "You are not Whitelisted.",
            "Sent Amount Not Enough",
            "Max 20 Allowed.",
            "insufficient funds",
            "Sale is Paused.",
            "mint at least one token",
            "max per transaction 20",
            "Not enough tokens left",
            "incorrect ether amount",
            "5 tokens per wallet allowed in presale",
            "10 tokens per wallet allowed in publicsale",
            "Invalid merkle proof",
            "Not enough tokens allowed in current phase",
            "Sold Out",
            "No more tokens left in current phase",
            "Wallet limit Reached"

        ]

        for (let i = 0; i < error_list.length; i++) {
            if (temp_error.includes(error_list[i])) {
                // set ("Transcation Failed")
                // alert(error_list[i]);
                swal("Alert!", error_list[i], "warning");
            }
        }
    }

    const sale_controller = async () => {
        if (saleStatus === 1) {
            //presale
            // await presalemint_nft();
        } else if (saleStatus === 2) {
           await mint_nft();
        }
    }

    const mint_nft = async () => {

        if (Web3.givenProvider) {
            try {
                const estemated_Gas = await contract.methods.publicMint().estimateGas({
                    from: address,
                    value: price * mintNumber,
                });

                const result = await contract.methods.publicMint().send({
                    from: address,
                    value: price * mintNumber,
                    gas: estemated_Gas
                });

            } catch (error) {
                show_error_alert(error);
            }
        }
    }


    return (
        <>
        <section id="hero">
          <div className="hero bg-black">
            <div className="container-xl h-100 py-4 py-md-5">
              <div className="row h-100 align-items-center flex-wrap-reverse">
                <div className="col-md-5">
                  <div className="wrapper">
        <p className="fs-16 lh-sm text-white text-uppercase mb-4">A <b>Metaverse</b>, <b>NFT</b>, And <b>ART</b> Innovation Conference</p>
                    <p className="fs-30 lh-1 text-mastad mb-3 mb-md-4">November 28-30, 2022</p>
                    <h1 className="fs-60-2 text-white lh-1 text-uppercase mb-2 mb-md-4">Eden Roc</h1>
                    <p className="fs-48 text-white lh-1 mb-5 mb-md-4">Miami Beach, FL</p>
                    <div className="input-group mb-3 mt-4">
                      <label className="input-group-text text-uppercase" htmlFor="mintQuantity">Mint Quantity</label>
                      <select
                            disabled={saleStatus !== 2}
                        onChange={e => {
                            setMintNumber(e.currentTarget.value);
                        }} className="text-white outline-none border-none p-2 bg-slate/50 bg-transparent"
                        defaultValue={1}
                        >
                            <option className="text-black">1</option>
                            <option className="text-black">2</option>
                        </select>
                    </div>
                      {address.length > 0 &&  <><p className="fs-16 lh-sm text-white text-uppercase mb-4">CONNECTED ACCOUNT: <br />{address}</p><button onClick={mintButtonClickHandler} disabled={saleStatus !== 2} className="btn btn-border">MINT PASS ( <span className="text-mastad">{ 0.3 * mintNumber } ETH</span> )</button></> }
                      {address.length === 0 &&  <button className="btn btn-border" onClick={connect_wallet}>CONNECT</button>}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="wrapper position-relative ps-md-5">
                    <div className="powered-by d-flex align-items-center justify-content-end position-absolute top-0 end-0">
                      <p className="fs-18 lh-1 text-mastad text-uppercase mb-0">Powered By</p>
                      <img src="img/tokenproof.png" alt="Tokenproof" className="img-fluid" />
                    </div>
                    <img src="img/logo.png" alt="Metaverse Miami" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
    );
};

export default Hero;
