import {React} from 'react';
function Speakers () {
 
    return (
        <section id="speakers">
        <div className="speakers py-md-5">
          <div className="container-xl py-5">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="wrapper mb-4 mb-md-5">
                  <h2 className="fs-72 lh-1 text-capitalize text-center text-white"><span className="text-mastad">FEATURING</span></h2>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/keithgrossman" target="_blank">
                  <img src="img/grossman.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/avery_akkineni" target="_blank">
                  <img src="img/avery.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/bobbyhundreds" target="_blank">
                  <img src="img/bobbyh.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/lenna_clay" target="_blank">
                  <img src="img/lenna.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
     <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/andr3w" target="_blank">
                  <img src="img/wang.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/natealexnft" target="_blank">
                  <img src="img/nate.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/sh3k1n4h" target="_blank">
                  <img src="img/sh3.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
     <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/jholguin" target="_blank">
                  <img src="img/julian.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="tropofarmer" target="_blank">
                  <img src="img/tropo.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/swansit" target="_blank">
                  <img src="img/swan.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/illadaproducer" target="_blank">
                  <img src="img/illa.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/swopes" target="_blank">
                  <img src="img/swopes.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/iamboredbecky" target="_blank">
                  <img src="img/becky.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
     <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/nicolebehnam" target="_blank">
                  <img src="img/nicole.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/degentraland" target="_blank">
                  <img src="img/degentraland.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/simon_goldberg" target="_blank">
                  <img src="img/simon.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/farokh" target="_blank">
                  <img src="img/farokh.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/vgfreakxbl" target="_blank">
                  <img src="img/vgf.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
               <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/goku_nft" target="_blank">
                  <img src="img/goku.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
             
     <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/coryvanlew" target="_blank">
                  <img src="img/cory.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/realjonahblake" target="_blank">
                  <img src="img/jonah.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/nftignition" target="_blank">
                  <img src="img/ignition.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/piovincenzo_" target="_blank">
                  <img src="img/pio.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/allnick" target="_blank">
                  <img src="img/allnick.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/speculatorart" target="_blank">
                  <img src="img/spec.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/legend" target="_blank">
                  <img src="img/post.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/davidbianchi" target="_blank">
                  <img src="img/bianchi.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
     <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/spottiewifi" target="_blank">
                  <img src="img/spottie.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/hunterorell" target="_blank">
                  <img src="img/hunter.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/lastraum" target="_blank">
                  <img src="img/lastraum.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/ginotheghost" target="_blank">
                  <img src="img/gino.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/KenobiDesigns" target="_blank">
                  <img src="img/kenobi.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/jnsilva_" target="_blank">
                  <img src="img/silva.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/mecdot" target="_blank">
                  <img src="img/mec.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/clayjoons" target="_blank">
                  <img src="img/joons.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/austin_hurwitz" target="_blank">
                  <img src="img/austin.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
         
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/leahlamarr" target="_blank">
                  <img src="img/leah.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/justmaiko" target="_blank">
                  <img src="img/maiko.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/themiamiape" target="_blank">
                  <img src="img/rod.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/defidefenselaw" target="_blank">
                  <img src="img/defilaw.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/adultarts" target="_blank">
                  <img src="img/adultarts.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/ldf_gm" target="_blank">
                  <img src="img/ldf.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/NeerMcD" target="_blank">
                  <img src="img/neer.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/giovannigussen" target="_blank">
                  <img src="img/giovanni.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
          <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/asadjmalik" target="_blank">
                  <img src="img/asad.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/bt" target="_blank">
                  <img src="img/bt.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/shawncoss" target="_blank">
                  <img src="img/coss.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/brycent" target="_blank">
                  <img src="img/brycent.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/smaroo" target="_blank">
                  <img src="img/smaroo.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/boredelonmusk" target="_blank">
                  <img src="img/bored.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/shontelle_layne" target="_blank">
                  <img src="img/shontelle.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
          <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/nittyb_xyz" target="_blank">
                  <img src="img/nitty.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/queenrusskiy" target="_blank">
                  <img src="img/russkiy.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/alexandermayes" target="_blank">
                  <img src="img/mayas.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/djabatt" target="_blank">
                  <img src="img/batt.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/eliana_esq" target="_blank">
                  <img src="img/eliana.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/richerdintern" target="_blank">
                  <img src="img/intern.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/nftdefiland" target="_blank">
                  <img src="img/defiland.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/christinebarnum" target="_blank">
                  <img src="img/barnum.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
          <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/thetechrabbi" target="_blank">
                  <img src="img/rabbi.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/gregjrnorman" target="_blank">
                  <img src="img/norman.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/anonsvoice" target="_blank">
                  <img src="img/anon.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/justinmmelillo" target="_blank">
                  <img src="img/melillo.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/morewillie" target="_blank">
                  <img src="img/willie.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/readyplayerrich" target="_blank">
                  <img src="img/rich.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
             
         <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/osf_nft" target="_blank">
                  <img src="img/osf.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/_jeffnicholas_" target="_blank">
                  <img src="img/nicholas.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/redactedpride" target="_blank">
                  <img src="img/brittany.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/greatmando_nft" target="_blank">
                  <img src="img/mando.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/illmindproducer" target="_blank">
                  <img src="img/illmind.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/brookejlacey" target="_blank">
                  <img src="img/brooke.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/BennyAtTheBank" target="_blank">
                  <img src="img/benny.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/ParinHeidari" target="_blank">
                  <img src="img/parin.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/weareamrNFT" target="_blank">
                  <img src="img/kai.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/_shanicebest" target="_blank">
                  <img src="img/shanice.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
      <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/swagginscrypto" target="_blank">
                  <img src="img/swaggins.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
     <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/lindseybyrnes" target="_blank">
                  <img src="img/byrnes.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div className="wrapper">
                <a href="https://twitter.com/wolf_financial" target="_blank">
                  <img src="img/gav.png" alt="speaker" className="img-fluid" />
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
export default Speakers;
