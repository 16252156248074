import './App.css';
import Header from './Header'
import Hero from './Hero'
import Partners from './Partners'
import Passes from './Passes'
import Speakers from './Speakers'
import Marketplace from './Marketplace'
import Sponsors from './Sponsors'
import Footer from './Footer'

function App() {
  return (
    <div>

        {/* NavBar Start */}
      <Header />
        {/* NavBar End */}
        {/* Hero Section Start */}
      <Hero/>
        {/* Hero Section End */}
        {/* Partners Start */}
        <Partners />
        {/* Partners End */}
        {/* Passes Start */}
        <Passes />
        {/* Passes End */}
        {/* Speakers Start */}
        <Speakers />
        {/* Speakers End */}
        {/* Marketplace Start */}
        <Marketplace />
        {/* Marketplace End */}
        {/* Sponsors Start */}
        <Sponsors />
        {/* Sponsors End */}
        {/* Footer Start */}
        <Footer />

      </div>
  );
}

export default App;
